@import './variables.scss';
body{
    margin: 0;
}
.terms{
    &-wrapper{
        background: url('../../_shared/shared/assets/images/bmw-background-image.jpg') no-repeat scroll 100% 0;
        background-size: cover;
        padding: 50px 0;
    }
    &-accept{
        #termsContent {
            & h1 {
              display: none;
            }
        }
        & .page-terms-and-conditions .terms-and-conditions-container{
            padding: 30px 30px 0px;
            @media (max-width: $xlarge){
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
    &-content{
        font-family: $helvetica;
    }
}

.dialog-container {
  padding: 40px 30px 20px !important;
  max-width: 580px !important;

  .fs-text.title {
    margin-top: 0 !important;
    padding-top: 10px !important;
    text-transform: uppercase !important;
    font-size: 18px !important;
    line-height: 21px !important;
    font-weight: 700 !important;
    color: var(--dark) !important;
    margin-bottom: 20px !important;
    font-family: $helvetica !important;

    &:after {
      content: "";
      width: 40px;
      display: block;
      margin-top: 15px;
      border: 0.5px solid var(--dark);
    }
  }


  .dialog-bodytext {
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 20px !important;
    font-family: $helvetica !important;
    color: var(--dark) !important;
    font-weight: 400 !important;

    @media(max-width: $xmedium) {
      margin-bottom: 20px !important;
      font-size: 12px !important;
      line-height: 18px !important;
    }
  }

  .dialog-buttonmultiple {
    justify-content: flex-end;
    padding-top: 20px;
    @media(max-width: $mobile) {
      flex-direction: column !important;
    }

    & .dialog-button {
      width: auto;
      padding-top: 20px;

      @media(max-width: $mobile) {
        width: 100%;
        padding-top: 10px;
      }

      &:first-child {
        margin: 0;
      }

      & .fs-button {
        &-btn {
          background-color: var(--primary-blue) !important;
          border: 1px solid var(--primary-blue) !important;
          color: var(--white) !important;
          font-family: $helvetica !important;
          border-radius: 0;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 15px 30px;
          opacity: 1;
          cursor: pointer;
          margin: 20px 0 10px 10px;
          line-height: normal;
          height: auto !important;
          width: auto;

          &:hover {
            background-color: var(--white) !important;
            border: 1px solid var(--primary-blue) !important;
            color: var(--primary-blue) !important;
          }

          @media(max-width: $mobile) {
            width: 100%;
            margin: 0;
          }

          &-disabled {
            cursor: not-allowed;
            opacity: .5;

            &:hover {
              background-color: var(--primary-blue) !important;
              border: 1px solid var(--primary-blue) !important;
              color: var(--white) !important;
            }
          }

          &-secondary {
            background-color: var(--white) !important;
            border: 1px solid var(--border-gray-dark) !important;
            color: var(--dark) !important;
            padding: 15px 30px !important;
            margin: 20px 0 10px 10px !important;
            line-height: normal;
            height: auto !important;
            width: auto;

            &:hover {
              border: 1px solid var(--black) !important;
              color: var(--dark) !important;
            }

            @media(max-width: $mobile) {
              width: 100%;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }


  &#sessionTimeOutDialog {
    padding: 40px 30px 15px !important;
    @media(max-width: $mobile) {
      max-width: 300px !important;
    }
    &.dialog-container{
      .fs-text.title{
        padding-top: 10px;
        text-transform: uppercase !important;
        font-size: 18px !important;
        line-height: 21px !important;
        font-weight: 700 !important;
        margin-bottom: 20px !important;
        @media(max-width: $tablet){
          margin-bottom: 20px !important;
        }
        @media(max-width: $mobile) {
          margin-bottom: 15px !important;
          letter-spacing: .35px;
        }
        &::after{
          content: "";
          width: 40px;
          display: block;
          position: static;
          background-color: #221f1f;
          margin-top: 15px;
        }
      }
      .dialog-bodytext{
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: .5px !important;
        margin-bottom: 20px !important;
        @media(max-width: $tablet){
          font-size: 15px !important;
          margin-bottom: 40px !important;
        }
        @media(max-width: $mobile){
          font-size: 12px !important;
          line-height: 1.5 !important;
          letter-spacing: normal !important;
        }
      }
    }
    .dialog-buttonmultiple {
    flex-direction: row;
      justify-content: flex-end;
      .dialog-button{
        padding-top: 0;
        &:first-child{
          @media(max-width: $mobile){
            padding-top: 10px;
          }
        }
      }
      @media (max-width: $mobile){
        flex-direction: column-reverse !important;
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }

  &#communicationPreferenceModel, &#preferenceErrorDialogModel, 
  &#fsLeavingDialogModel {
    padding: 30px 30px 15px !important;
    max-width: 536px !important;

    @media(max-width: $mobile) {
      max-width: 300px !important;

      .fs-text.title {
        font-size: 16px !important;
      }
      .dialog-buttonmultiple {
        @media (max-width: $mobile){
          flex-direction: column-reverse !important;
          padding: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

}



.fs-breadcrumb .breadcrumbs {
  div {
    @media (max-width: $xmedium){
      font-size: 15px !important;
    }
  }

    .fs-icon.breadcrumbBack{
      @media (max-width: $xmedium){
        width: 5px;
      }
  }
}

.mat-mdc-option-text{
  font-family: $helvetica !important;
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    background: var(--white) !important;
    border: 1px solid var(--input-border-gray) !important;
    color: var(--header-font-color) !important;
    font-size: 10px !important;
    line-height: 15px !important;
    padding: 15px !important;
    border-radius: 0 !important;
    box-shadow: 0px 10px 20px rgba(var(--black), var(--opacity02)) !important;
    font-family: $helvetica !important;
    max-width: 220px !important;
    letter-spacing: normal;
  }
}
 
@media print {
  .no-print{
      display: none !important
  }
}